import React, { useState, useEffect } from "react";
import {
  Container,
  ImageAndTitle,
  Title,
  DivDropzone,
  DateAndCheck,
  Fields,
  DivFields,
  Button,
  InfoTelephone,
  Alert,
} from "./style";
import IconExame from "../../../../assets/home/IconExame.png";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import RequestButton from "../../../../components/RequestButton";
import whatsapp from "../../../../assets/components/whatsapp.png";
import Telefone from "../../../../assets/components/telefone_black.png";
import Dropzone from "react-dropzone-uploader";
import MenuItem from "@mui/material/MenuItem";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import conexao from "../../../../Services/index";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import "./dropzone.css";
import ModalAlert from "../../../../components/ModalAlert";

export default function RequestExam() {
  const states = [
    { label: "AC", value: "AC" },
    { label: "AL", value: "AL" },
    { label: "AP", value: "AP" },
    { label: "AM", value: "AM" },
    { label: "BA", value: "BA" },
    { label: "CE", value: "CE" },
    { label: "DF", value: "DF" },
    { label: "ES", value: "ES" },
    { label: "GO", value: "GO" },
    { label: "MA", value: "MA" },
    { label: "MT", value: "MT" },
    { label: "MS", value: "MS" },
    { label: "MG", value: "MG" },
    { label: "PA", value: "PA" },
    { label: "PB", value: "PB" },
    { label: "PR", value: "PR" },
    { label: "PE", value: "PE" },
    { label: "PI", value: "PI" },
    { label: "RJ", value: "RJ" },
    { label: "RN", value: "RN" },
    { label: "RS", value: "RS" },
    { label: "RO", value: "RO" },
    { label: "RR", value: "RR" },
    { label: "SC", value: "SC" },
    { label: "SP", value: "SP" },
    { label: "SE", value: "SE" },
    { label: "TO", value: "TO" },
  ];

  const state = {
    date: { value: "", error: false },
    checkbox: { value: false, error: false },
    cidade: { value: "", error: false },
    estado: { value: "", error: false },
    photo: { value: "", error: false },
  };
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState(state);
  const [isOk, setIsOk] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const { setComponents, dataClient } = StateGlobal();
  const [image, setImage] = useState("");

  function handleIsOpen() {
    setOpen(!open);
    setComponents(5);
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function checkFormSend() {
    if (form.checkbox.value === true) {
      if (
        form.checkbox.value !== false &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(false);
      } else {
        setIsOk(true);
      }
    } else {
      if (
        form.date.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(false);
      } else {
        setIsOk(true);
      }
    }
  }
  useEffect(() => {
    checkFormSend();
  }, [form]);

  function validate(value) {
    if (value === "" || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  function pegadados(e) {
    const { name, value, checked } = e;
    if (name === "date") {
      setForm({
        ...form,
        [name]: { value: value, error: validate(value) },
      });
    }
    if (name === "checkbox") {
      setForm({ ...form, [name]: { value: checked, error: validate(value) } });
    }
    if (name === "cidade") {
      setForm({
        ...form,
        [name]: { value: value, error: validate(value) },
      });
    }
    if (name === "estado") {
      setForm({ ...form, [name]: { value: value, error: validate(value) } });
    }
    // if (name === "photo") {
    //   setForm({ ...form, [name]: { value: value, error: validate(value) } });
    // }
  }

  function send() {
    if (form.checkbox.value === true) {
      if (
        form.checkbox.value !== false &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(true);
        conexao
          .post("api/user/schedule/exam/request", {
            planned_date: "",
            city: form.cidade.value,
            state: form.estado.value,
            photo: image,
          })
          .then((res) => {
            setIsOk(true);
            setAlertType("success");
            setShowAlertOk(true);
            setTimeout(() => {
              window.location.href =
                "https://api.whatsapp.com/send?phone=5515997970092";
              // setComponents(1);
            }, 1000);
          })
          .catch((error) => {
            setIsOk(false);
            setAlertType("error");
            setShowAlertError(true);
            setTimeout(() => {
              setIsOk(false);
            }, 2000);
          });
      }
      // podemos fazer uma verificação pelo erro se necessário
      // else if (form.user.value === "" || form.password.value === "") {
      //   setForm({
      //     ...form,
      //     user: { error: validate(form.user.value) },
      //     password: { error: validate(form.password.value) },
      //   });
      // }
    } else {
      if (
        form.date.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(true);
        conexao
          .post("api/user/schedule/exam/request", {
            planned_date: form.date.value,
            city: form.cidade.value,
            state: form.estado.value,
            photo: image,
          })
          .then((res) => {
            setIsOk(true);
            setAlertType("success");
            setShowAlertOk(true);
            setTimeout(() => {
              setComponents(1);
            }, 2000);
          })
          .catch((error) => {
            setAlertType("error");
            setShowAlertError(true);
            setTimeout(() => {
              setIsOk(false);
            }, 2000);
          });
      }
    }
  }

  // drop-zone functions

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  function getBase64(file) {
    if (file.size > 10000000) {
      alert(
        "Documento maior que 10MB, por favor verifique o tamanho do arquivo."
      );
      return;
    }

    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      setImage(reader.result);
    };
  }

  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
  //   // if (status === 'headers_received') {

  //   // } else if (status === 'aborted') {
  //   //   handleSubmit()
  //   // }
  // }

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      getBase64(file);
    }
    {
      /* eslint-disable */
    }
    if (status === "removed") {
      setImage("");
    }
  };


  return (
    <Container>
      <ImageAndTitle>
        <img
          src={IconExame}
          width={25}
          alt="icon_schedule"
        />
        <Title>Solicitação de Exame</Title>
      </ImageAndTitle>
      <label
        id="date"
        style={{ paddingLeft: "20px" }}
      >
        Data Planejada
      </label>
      <DateAndCheck>
        <TextField
          disabled={form.checkbox.value === true ? true : false}
          name="date"
          id="date"
          size="small"
          fullWidth
          type="date"
          style={{ maxWidth: "180px" }}
          onChange={(e) => {
            pegadados(e.target);
          }}
        />
        <Checkbox
          style={{ color: "#51C6D6" }}
          name="checkbox"
          onChange={(e) => {
            pegadados(e.target);
          }}
        />
        <p>O mais breve possível</p>
      </DateAndCheck>
      <DivFields>
        <Fields>
          <label id="city">Cidade</label>
          <TextField
            id="city"
            size="small"
            type="text"
            name="cidade"
            onChange={(e) => {
              pegadados(e.target);
            }}
          />
        </Fields>
        <Fields>
          <label
            id="state"
            style={{ marginLeft: "15px" }}
          >
            Estado
          </label>

          <TextField
            name="estado"
            style={{ marginLeft: "15px" }}
            id="state"
            size="small"
            type="text"
            select
            fullWidth
            onChange={(e) => {
              pegadados(e.target);
            }}
          >
            {states.map((states) => (
              <MenuItem
                key={states.value}
                value={states.value}
              >
                {states.value}
              </MenuItem>
            ))}
          </TextField>
        </Fields>
      </DivFields>
      {/* <DivDropzone>
        <Dropzone
          onChangeStatus={handleChangeStatus}
          getUploadParams={getUploadParams}
          // onChange={(e) => setRegister({ ...register, cpf: e.target.value })}
          maxFiles={1}
          canCancel={false}
          multiple={false}
          accept="image/*"
          inputContent={`Anexar foto da solicitação do exame`}
        />
      </DivDropzone> */}
      <Button>
        <RequestButton
          text="Solicitar Exame"
          icon={whatsapp}
          disabled={isOk}
          funcao={send}
        />
      </Button>
      <InfoTelephone>
        <p>ou ligue:</p>
        <div>
          <img
            src={Telefone}
            alt="Telefone"
          />
          <span>Tel: (15) 99797 0092</span>
        </div>
      </InfoTelephone>

      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Agendamento solicitado"
              : "Erro ao Agendar!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
      {dataClient?.block_status === true ? (
        <ModalAlert
          status={open}
          funcao={handleIsOpen}
        />
      ) : (
        ""
      )}
    </Container>
  );
}
