import React, { useState, useContext } from "react";
import Logo from "../../../assets/Logo.png";
import { FormHelperText, TextField } from "@mui/material";
import InputPassword from "../../../components/Inputs/password";
import PrimaryButton from "../../../components/PrimaryButton";
import { validasenha } from "../../../components/validates/Validate";
import { email } from "../../../components/validates/ValidateForm";
import conexao from "../../../Services/index";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import { saveToken } from "../../../Services/auth";
import {
  Container,
  HeaderLogin,
  FormLogin,
  EsqueceuSenha,
  LinhaComTexto,
  LinhaTexto,
  FormCadastro,
  Alert,
} from "./style";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import { useNavigate } from "react-router";

export default function Login() {
  const navigate = useNavigate();

  const state = {
    user: { value: "", error: false },
    password: { value: "", error: false },
  };
  const [form, setForm] = useState(state);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [auxStatus, setAuxStatus] = useState(false);
  const [textError, setTextError] = useState("");
  const { setFirstAccessInfos } = useContext(RegisterContext);
  const [loading, setLoading] = useState(false);

  function pegadados(e) {
    const { name, value } = e;
    if (name === "user") {
      setForm({
        ...form,
        [name]: { value: value.toLowerCase(), error: email(value) },
      });
    }
    if (name === "password") {
      setForm({ ...form, [name]: { value, error: validasenha(value) } });
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function changeStatusError() {
    setAuxStatus(true);
    setAlertType("error");
    setShowAlertError(true);
  }

  async function HandleSubmit() {
    setLoading(true);
    try {
      if (form.user.value !== "" && form.password.value !== "") {
        const res = await conexao.post("api/user/login", {
          email: form.user.value,
          password: form.password.value,
        });
        if (res.data.error === "Usuário não está ativo") {
          changeStatusError();
        } else {
          if (res.data.token) {
            setAuxStatus(false);
            saveToken(res.data.token);
            setAlertType("success");
            setShowAlertOk(true);
            setTimeout(() => {
              window.location.href = "/home";
            }, 1500);
          } else if (form.user.value === "" || form.password.value === "") {
            setAuxStatus(false);
            setForm({
              ...form,
              user: { error: email(form.user.value) },
              password: { error: validasenha(form.password.value) },
            });
          }
        }

        setTextError(res.data.message);
        if (res.data.error === "true") {
          setAuxStatus(false);
          setAlertType("error");
          setShowAlertError(true);
        }
        if (res.data.first_access === "true" && res.data.error === "false") {
          setFirstAccessInfos(res.data);
          setTimeout(() => {
            navigate("/primeiro-acesso/1");
          }, 1500);
          // setAuxStatus(false);
          // setAlertType("error");
          // setShowAlertError(true);
        }
      }
    } catch (err) {
      setTextError("Estamos com um erro no momento! Volte mais tarde...");
      setAuxStatus(false);
      setAlertType("error");
      setShowAlertError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <HeaderLogin>
        <div>
          <img
            alt="logo"
            src={Logo}
          ></img>
        </div>
      </HeaderLogin>

      <FormLogin>
        <p>CPF ou E-mail de cadastro</p>
        <TextField
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
          name="user"
          onChange={(e) => {
            pegadados(e.target);
          }}
          error={form.user.error}
          // helperText={form.user.error}
          size="small"
          placeholder="Digite seu CPF ou E-mail de cadastro"
        />
        <FormHelperText
          style={{ color: "#ff4040", marginTop: "0px !important" }}
        >
          {form.user.error}
        </FormHelperText>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Senha</p>
          <EsqueceuSenha
            onClick={() => {
              window.location.href = "/esqueceu-a-senha";
            }}
          >
            Esqueceu sua senha?
          </EsqueceuSenha>
        </div>
        <InputPassword
          pegadados={pegadados}
          error={form.password.error}
        />

        <div style={{ marginTop: "35px" }}>
          <PrimaryButton
            disabled={loading}
            text="Acessar"
            funcao={HandleSubmit}
          />
        </div>
      </FormLogin>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <LinhaComTexto />
        <LinhaTexto>ou</LinhaTexto>
      </div>

      <FormCadastro>
        <p>Não tem cadastro?</p>
        <div>
          <PrimaryButton
            text="Cadastrar"
            funcao={""}
            route="/cadastro/1"
          />
        </div>
      </FormCadastro>

      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Login efetuado com sucesso!"
              : auxStatus === true
              ? "Usuário Inativo"
              : textError
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
    </Container>
  );
}
