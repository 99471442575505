import * as React from "react";
import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SecondButton from "../SecondButton";
import Mapa from "../../assets/components/mapa.png";
import Telefone from "../../assets/components/telefone.png";
import whatsapp from "../../assets/components/whatsapp.png";
import { X } from "react-feather";
import { StateGlobal } from "../../Utility/Context/RegisterContext";
import CircularProgress from "@mui/material/CircularProgress";
// web.cjs is required for IE11 support

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  paddingLeft: "30px",
};

export default function ModalDetailsHistoric(props) {
  const { infoHistoryById } = StateGlobal();
  const [load, setLoad] = useState(true);

  function none() {}

  function timeLoad() {
    setTimeout(() => {
      setLoad(false);
    }, [1000]);
  }

  useEffect(() => {
    setLoad(true);
    timeLoad();
  }, [props.funcao]);

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <X
            color="#08559F"
            style={{ position: "absolute", right: "15px", top: "15px" }}
            onClick={props.funcao}
          />
          {props.info.status === "finalizado" && load === false ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginRight: "10px",
                    justifyContent: "center",
                  }}
                >
                  <h2>{infoHistoryById.day}</h2>
                  <h4 style={{ fontSize: "14px", textTransform: "capitalize" }}>
                    {infoHistoryById.month_text}{" "}
                  </h4>
                  <h4 style={{ fontSize: "12px" }}>{infoHistoryById.year}</h4>
                  <p
                    style={{
                      color: "red",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {infoHistoryById.hour}
                  </p>
                </div>
                <div style={{ width: "65%" }}>
                  <h3 style={{ fontSize: "17px" }}>Consulta Médica</h3>
                  <p style={{ fontSize: "12px" }}>
                    Pocedimento: {props.info.procedimento}
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    R${" "}
                    {new Intl.NumberFormat("pt-BR", {
                      minimumFractionDigits: 2,
                    }).format(props.info.valor)}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      backgroundColor:
                        props.info.status === "finalizado"
                          ? "#2EDE6A"
                          : "#EDDE5A",
                      borderRadius: "25px",
                      width: "130px",
                      display: "flex",
                      justifyContent: "center",
                      color: "#FFF",
                      alignContent: "center",
                      marginTop: "5px",
                      height: "18px",
                    }}
                  >
                    Status: {props.info.status}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "165px",
                  justifyContent: "space-around",
                }}
              >
                <a
                  href={`tel:+551533344000`}
                  style={{ textDecoration: "none" }}
                >
                  <SecondButton
                    text="Ligar agora"
                    icon={Telefone}
                    funcao={none}
                  />
                </a>{" "}
                <a
                  href={`https://api.whatsapp.com/send?phone=5515997970092`}
                  style={{ textDecoration: "none" }}
                >
                  <SecondButton
                    text="Enviar mensagem"
                    icon={whatsapp}
                    funcao={none}
                  />
                </a>
                <SecondButton
                  disabled={true}
                  text="Como chegar"
                  icon={Mapa}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={30}
                style={{ color: "#08559F" }}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
