export function campovazio(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  return false
}

export function senhavalidate(e) {
  if (e.length < 7) {
    return true
  }
  return false
}


export function email(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  // if (!e.includes('@')) {
  //   return 'Email inválido'
  // }
  return false
}
export function CPF(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  if (e.length <= 11) {
    return 'Preencha todo o número do cpf'
  }
  return false
}
export function RG(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  return false
}
export function phone(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  if (e.length < 11) {
    return 'Telefone inválido'
  }
  return false
}
export function cepValid(e) {
  if (e === '') {
    return 'Esse campo precisa ser preenchido'
  }
  if (e.length < 10) {
    return 'CEP inválido'
  }
  return false
}
