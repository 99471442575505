import React, { useState, useEffect } from "react";
import { StateGlobal } from "../../../../../../Utility/Context/RegisterContext";
import {
  Container,
  Content,
  ImageAndTitle,
  Title,
  Circulo,
  LineProgres,
  ContainerProgres,
  ContainerLabelProgres,
  ContainerLabelSpace,
  ContainerLabel,
  LabelText,
  Button,
  ButtonBack,
  TextButtonBack,
  DivCard,
  TextRecharge,
  DivText,
  Form,
  Fields,
  DivRow,
} from "./style";
import Cards from "react-credit-cards";
import { MenuItem, Select, TextField } from "@mui/material";
import CardRechargeIcon from "../../../../../../assets/home/CardRechargeIcon.png";
import PrimaryButton from "../../../../../../components/PrimaryButton";
import { api_payhow } from "../../../../../../Services";
import { CircularProgress } from "@mui/material";
import "react-credit-cards/es/styles-compiled.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router";
import { validaCpfCnpj } from "../../../../../../components/validates/Validate";

export default function PaymentData() {
  const {
    dataClient,
    setRechargeSteps,
    info_card,
    setInfo_card,
    valueRecharged,
    setComponents,
    setAddress_info,
    setValueRecharged,
  } = StateGlobal();
  const MySwal = withReactContent(Swal);
  const [isOk, setIsOk] = useState(true);
  const [focus, setFocus] = useState(null);
  const [card_flag, setCard_flag] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [parcels, setParcels] = useState([]);
  const [selectedParcel, setSelectedParcel] = useState(1);
  const [selectedParcelValue, setSelectedParcelValue] = useState(
    Number(valueRecharged?.replace(/[^0-9s]/g, ""))
  );

  // Função para calcular as parcelas com base em valueRecharged
  const calculateParcels = (value) => {
    const maxInstallments = 12;
    const minInstallmentValue = 80;

    // Substitui os pontos de milhar e troca a vírgula decimal por ponto
    const valueFormatted = value.replace(/\./g, "").replace(",", ".");
    const valueNumber = parseFloat(valueFormatted);

    let availableParcels = [];
    for (let i = 1; i <= maxInstallments; i++) {
      const parcelValue = valueNumber / i;
      if (parcelValue >= minInstallmentValue || i === 1) {
        availableParcels.push({
          label: `${i}x R$${parcelValue.toFixed(2).replace(".", ",")}`,
          value: i,
          parcelValue: parcelValue.toFixed(2).replace(".", ","),
        });
      } else {
        break; // Sai do loop quando o valor da parcela é menor que o mínimo
      }
    }

    return availableParcels;
  };

  // Atualiza as parcelas quando valueRecharged mudar
  useEffect(() => {
    if (valueRecharged) {
      const newParcels = calculateParcels(valueRecharged);
      setParcels(newParcels);
    }
  }, [valueRecharged]);

  function bodyValidate() {
    if (
      info_card.card_cvv !== "" &&
      info_card.card_cvv?.length === 3 &&
      info_card.card_expiration_date !== "" &&
      info_card.card_number !== "" &&
      // info_card.card_number?.length === 19 &&
      info_card.card_holder_name !== "" &&
      info_card.document_number !== "" &&
      info_card.card_cvv !== null &&
      info_card.card_expiration_date !== null &&
      info_card.card_number !== null &&
      info_card.card_holder_name !== null &&
      info_card.document_number !== null &&
      info_card.card_cvv !== undefined &&
      info_card.card_expiration_date !== undefined &&
      info_card.card_number !== undefined &&
      info_card.card_holder_name !== undefined &&
      info_card.document_number !== undefined
    ) {
      setIsOk(false);
    } else {
      setIsOk(true);
    }
  }

  const getCardFlag = (cardnumber) => {
    cardnumber = cardnumber?.replace(/[^0-9]+/g, "");

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      master: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        setCard_flag(flag);
        return flag;
      }
    }

    return false;
  };

  useEffect(() => {
    if (info_card.card_number?.length > 1) {
      getCardFlag(info_card.card_number);
    }
  }, [info_card.card_number]);

  const handleInputFocus = (e) => {
    if (e.target.name === "card_number") setFocus("number");
    else if (e.target.name === "card_holder_name") setFocus("name");
    else if (e.target.name === "card_expiration_date") setFocus("expiry");
    else if (e.target.name === "card_cvv") setFocus("cvc");
  };

  useEffect(() => {
    bodyValidate();
  }, [info_card]);

  function dateMask(value) {
    value = value
      ?.replace(
        /[^0-9]/g,
        "" // To allow only numbers
      )
      ?.replace(
        /^([2-9])$/g,
        "0$1" // To handle 3 > 03
      )
      ?.replace(
        /^(1{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      ?.replace(
        /^0{1,}/g,
        "0" // To handle 00 > 0
      )
      ?.replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        "$1/$2" // To handle 113 > 11/3
      );

    return value;
  }

  function clearAll() {
    setInfo_card({
      ...info_card,
      card_number: "",
      card_cvv: "",
      card_expiration_date: "",
      card_holder_name: "",
      document_number: "",
    });
    setAddress_info({
      zip_code: "",
      street: "",
      neighborhood: "",
      number: "",
      complement: "",
      city: "",
      state: "",
    });
    setValueRecharged("");
  }

  const card_expiration_split = info_card.card_expiration_date.split("/");
  var str = info_card?.card_holder_name;
  var results = str.split(" ");
  var first = results.shift();
  var last = results.join(" ");

  async function handleSubmit() {
    setLoading(true);
    if (validaCpfCnpj(info_card.document_number)) {
      const valueRechargedNumber =
        typeof selectedParcelValue === "string"
          ? Number(selectedParcelValue.replace(/[^0-9]/g, ""))
          : selectedParcelValue;

      setInfo_card({ ...info_card, card_cvv: "" });
      try {
        fetch(
          `${process.env.REACT_APP_PAYHOW_URL}v1/ecommerce/checkout/cart/payment/process`,
          {
            method: "post",
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : "",
              Accept: "application/json",
              "Content-Type": "application/json",
              "public-key": localStorage.getItem("public_key"),
              "checkout-session": localStorage.getItem("checkout_session"),
              // 'Access-Control-Allow-Origin': '*'
            },

            body: JSON.stringify({
              payment_method: "payhow-gat",
              type: "credit_card",
              name_customer: info_card?.card_holder_name,
              first_name: first,
              last_name: last,
              document: "cpf",
              document_number: info_card.document_number?.replace(
                /[^0-9s]/g,
                ""
              ),
              email: dataClient.email,
              phone: dataClient.drsaude_configs.phone
                ? dataClient.drsaude_configs.phone
                : dataClient.drsaude_configs.whatsapp,
              token: "1",
              installments: String(selectedParcel),
              installments_value: valueRechargedNumber,
              card_number: info_card.card_number,
              card_month: card_expiration_split[0],
              card_year: card_expiration_split[1],
              card_ccv: info_card.card_cvv,
            }),
          }
        ).then((response) => {
          response
            .json()
            .then((responseValidate) => {
              if (responseValidate?.payment_status === "processing") {
                MySwal.fire({
                  position: "center",
                  icon: "success",
                  title: "Sua recarga está sendo processada...",
                  showConfirmButton: true,
                  timer: 4000,
                });
                setLoading(false);
                setComponents(0);
                navigate("/home", { replace: true });
                setRechargeSteps(1);
                clearAll();
              } else {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: responseValidate.errors.message,
                  showConfirmButton: true,
                  timer: 4000,
                });
              }

              if (responseValidate.errors.message.card_brand[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    "Digíte número do cartão novamente, bandeira não encontrada",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
              if (responseValidate.errors.message.card_cvv[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Digíte o número de segurança novamente",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
              if (responseValidate.errors.message.card_holder_name[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Digíte o nome do titular do cartão novamente",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
              if (responseValidate.errors.message.card_month_expiration[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Digíte a data de validade novamente",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
              if (responseValidate.errors.message.card_year_expiration[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Digíte a data de validade novamente",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
              if (responseValidate.errors.message.card_number[0]) {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Número do cartão inválido",
                  showConfirmButton: true,
                  timer: 4000,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setLoading(false);
      MySwal.fire({
        position: "center",
        icon: "error",
        title: "CPF ou CNPJ inválido",
        showConfirmButton: true,
        timer: 4000,
      });
    }
  }

  function maskCpfCnpj(v) {
    v = v?.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v?.replace(/(\d{3})(\d)/, "$1.$2");
      v = v?.replace(/(\d{3})(\d)/, "$1.$2");
      v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v?.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v?.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  }

  function mcc(v) {
    v = v?.replace(/\D/g, "");
    v = v?.replace(/^(\d{4})(\d)/g, "$1 $2");
    v = v?.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3");
    v = v?.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4");
    return v;
  }

  return (
    <Container>
      <Content>
        <ImageAndTitle>
          <img
            src={CardRechargeIcon}
            width={25}
            alt="icon_schedule"
          />
          <Title>Recarregar seu Cartão</Title>
        </ImageAndTitle>

        <ContainerProgres>
          <LineProgres>
            <Circulo />
            <Circulo />
            <Circulo />
          </LineProgres>
        </ContainerProgres>

        <ContainerLabelProgres>
          <ContainerLabelSpace>
            <ContainerLabel>
              <LabelText>Valor de recarga</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados pessoais</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados de pagamento</LabelText>
            </ContainerLabel>
          </ContainerLabelSpace>
        </ContainerLabelProgres>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "450px",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <DivCard>
              <Cards
                placeholders={{ name: "NOME DO TÍTULAR" }}
                cvc={info_card.card_cvv}
                expiry={info_card.card_expiration_date}
                focused={focus}
                name={info_card.card_holder_name}
                number={info_card.card_number}
              />
            </DivCard>
            <DivText>
              <TextRecharge>VALOR DA RECARGA: </TextRecharge>
              <TextRecharge>{`R$ ${valueRecharged}`}</TextRecharge>
            </DivText>
            <Form>
              <label htmlFor="document_number">
                CPF ou CNPJ do titular do cartão
              </label>
              <TextField
                type="tel"
                size="small"
                name="document_number"
                inputProps={{ maxLength: 18 }}
                value={
                  info_card.document_number
                    ? maskCpfCnpj(info_card.document_number)
                    : info_card.document_number
                }
                onFocus={(e) => handleInputFocus(e)}
                onChange={(e) => {
                  setInfo_card({
                    ...info_card,
                    document_number: e.target.value,
                  });
                }}
              />
              <Fields>
                <label htmlFor="card_number">Número do cartão</label>
                <TextField
                  type="tel"
                  size="small"
                  name="card_number"
                  inputProps={{ maxLength: 19 }}
                  value={
                    info_card.card_number
                      ? mcc(info_card.card_number)
                      : info_card.card_number
                  }
                  onFocus={(e) => handleInputFocus(e)}
                  onChange={(e) => {
                    setInfo_card({ ...info_card, card_number: e.target.value });
                  }}
                />
              </Fields>
              <Fields>
                <label htmlFor="card_holder_name">
                  Nome do titular do cartão
                </label>
                <TextField
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  size="small"
                  name="card_holder_name"
                  value={info_card.card_holder_name.toUpperCase()}
                  onFocus={(e) => handleInputFocus(e)}
                  onChange={(e) => {
                    setInfo_card({
                      ...info_card,
                      card_holder_name: e.target.value,
                    });
                  }}
                />
              </Fields>
              <DivRow>
                <Fields>
                  <label htmlFor="validade">Data de validade</label>
                  <TextField
                    type="tel"
                    size="small"
                    name="card_expiration_date"
                    value={dateMask(info_card.card_expiration_date)}
                    onFocus={(e) => handleInputFocus(e)}
                    onChange={(e) => {
                      setInfo_card({
                        ...info_card,
                        card_expiration_date: e.target.value,
                      });
                    }}
                  />
                </Fields>
                <Fields style={{ marginLeft: "15px" }}>
                  <label htmlFor="card_cvv">CVV</label>
                  <TextField
                    size="small"
                    type="tel"
                    name="card_cvv"
                    inputProps={{ maxLength: 3 }}
                    onFocus={(e) => handleInputFocus(e)}
                    onChange={(e) => {
                      setInfo_card({ ...info_card, card_cvv: e.target.value });
                    }}
                  />
                </Fields>
              </DivRow>
              <Fields>
                <label htmlFor="installments">Parcelamento</label>
                <Select
                  value={selectedParcel}
                  onChange={(e) => {
                    const selectedOption = parcels.find(
                      (option) => option.value === e.target.value
                    );
                    setSelectedParcel(e.target.value);
                    setSelectedParcelValue(selectedOption?.parcelValue);
                  }}
                  displayEmpty
                  size="small"
                >
                  {parcels.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Fields>
              <Button>
                <PrimaryButton
                  text="RECARREGAR AGORA"
                  disabled={isOk}
                  funcao={handleSubmit}
                />
              </Button>
            </Form>

            <ButtonBack
              onClick={() => {
                setRechargeSteps(2);
              }}
            >
              <TextButtonBack>Voltar</TextButtonBack>
            </ButtonBack>
          </>
        )}
      </Content>
    </Container>
  );
}
