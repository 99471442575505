import React, { useEffect, useState } from "react";
import { StateGlobal } from "../../../../../Utility/Context/RegisterContext";
import { BsCalendar } from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";

import {
  Container,
  Date,
  ContainerLine,
  ContainerCircle,
  Circle,
  ContainerInfor,
} from "./style";

export default function UsageHistory(props) {
  const { setRowId, setInfoHistoryById } = StateGlobal();

  return (
    <Container>
      <Date>
        <p id="Day">{props.info.day}</p>
        <p id="month">{props.info.month_text}</p>
        <p>{props.info.year}</p>
      </Date>
      <ContainerLine>
        <ContainerCircle>
          <Circle props={props.info.type} props2={props.info.sinal} />
        </ContainerCircle>
      </ContainerLine>
      <ContainerInfor props={props.info.type} props2={props.info.sinal}>
        {/* info value recharge */}
        <p id="TypeCard">{props.info.title}</p>
        {props.info.type === "recharge" ? (
          <p id="ValueCardPlus">+ {props.info.value}</p>
        ) : props.info.type === "transaction" &&
          props.info.sinal === "positivo" ? (
          <p id="ValueCardPlus">+ {props.info.value}</p>
        ) : props.info.type === "transaction" &&
          props.info.sinal === "negativo" ? (
          <p id="ValueCardPlus">- {props.info.value}</p>
        ) : props.info.type === "schedule" ? (
          <p id="ValueCardPlus"> {props.info.value}</p>
        ) : (
          ""
        )}

        {/* info clinic and payment */}
        {props.info.type === "recharge" ? (
          <p id="forma">Forma de pagamento: {props.info.method}</p>
        ) : props.info.type === "transaction" ? (
          <p>Clínica Médica - {props.info.receiver}</p>
        ) : (
          ""
        )}

        {/* info date */}
        {props.info.type === "schedule" ? (
          <>
            <label id="datePlus">Horário: {props.info.hour}</label>
            <p>Clínica Médica - {props.info.partner_name}</p>
            <div
              style={{
                display: "flex",
                width: "215px",
                justifyContent: "space-between",
                padding: "3px 0px",
                color: "#08559F",
                fontWeight: "600",
                fontSize: "10px",
                textDecoration: "underline",
              }}
            >
              <div
                style={{ display: "flex" }}
                onClick={() => {
                  props.open();
                  setRowId(props.info.id);
                  setInfoHistoryById(props.info);
                }}
              >
                <p style={{ display: "flex" }}>
                  {" "}
                  <BsCalendar
                    style={{ marginTop: "2px", marginRight: "3px" }}
                  />{" "}
                  Ver agendamento
                </p>
              </div>
              {/* <div>
                <p style={{ display: "flex" }}>
                  {" "}
                  <FaLocationArrow style={{ marginTop: "2px", marginRight: "3px" }}/>
                  Como chegar
                </p>
              </div> */}
            </div>
          </>
        ) : (
          <label>Horário: {props.info.hour}</label>
        )}
      </ContainerInfor>
    </Container>
  );
}
